// var lastScrollTop = 0;
// var navbar = document.getElementById("mainNav");
//
// window.addEventListener("scroll", function() {
//     var scrollTop = window.pageYOffset || document.documentElement.scrollTop;
//     var viewportWidth = window.innerWidth || document.documentElement.clientWidth;
//
//     if (scrollTop > lastScrollTop) {
//         navbar.style.top = "-200px";
//     } else {
//         navbar.style.top = "0";
//     }
//
//     if (scrollTop <= 0) {
//         navbar.style.background = "transparent";
//     } else {
//         // Check viewport width to determine linear gradient
//         if (viewportWidth < 768) {
//             navbar.style.background = "linear-gradient(to bottom, rgba(36, 40, 44, 0.9) 0%, rgba(36, 40, 44, 0.9) calc(100% - 16px), transparent calc(100% - 16px), transparent 100%)";
//         } else {
//             navbar.style.background = "linear-gradient(to bottom, rgba(36, 40, 44, 0.9) 0%, rgba(36, 40, 44, 0.9) calc(100% - 23px), transparent calc(100% - 23px), transparent 100%)";
//         }
//         navbar.style.transition = ".4s ease-in-out";
//     }
//
//     lastScrollTop = scrollTop <= 0 ? 0 : scrollTop;
// }, false);

var lastScrollTop = 0;
var navbar = document.getElementById("mainNav");
var navbarNav = document.querySelector(".navbar-nav");

window.addEventListener("scroll", function () {
    var scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    var viewportWidth = window.innerWidth || document.documentElement.clientWidth;
    var isWhiteBackground = navbarNav.classList.contains('white-background');

    if (scrollTop > lastScrollTop) {
        navbar.style.top = "-200px";
    } else {
        navbar.style.top = "0";
    }

    if (scrollTop <= 0) {
        navbar.style.background = "transparent";
    } else {
        // Change navbar background gradient based on viewport width and white-background class
        if (isWhiteBackground) {
            if (viewportWidth < 768) {
                navbar.style.background = "linear-gradient(to bottom, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) calc(100% - 16px), transparent calc(100% - 16px), transparent 100%)";
            } else {
                navbar.style.background = "linear-gradient(to bottom, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) calc(100% - 24px), transparent calc(100% - 24px), transparent 100%)";
            }
        } else {
            if (viewportWidth < 768) {
                navbar.style.background = "linear-gradient(to bottom, rgba(36, 40, 44, 0.9) 0%, rgba(36, 40, 44, 0.9) calc(100% - 16px), transparent calc(100% - 16px), transparent 100%)";
            } else {
                navbar.style.background = "linear-gradient(to bottom, rgba(36, 40, 44, 0.9) 0%, rgba(36, 40, 44, 0.9) calc(100% - 24px), transparent calc(100% - 24px), transparent 100%)";
            }
        }
        navbar.style.transition = ".4s ease-in-out";
    }

    lastScrollTop = scrollTop <= 0 ? 0 : scrollTop;
}, false);


import AOS from 'aos'
import 'aos/dist/aos.css'

AOS.init({
    easing: 'ease-out-quart',
    delay: 0,
    duration: 1000
})

import './components/toastify';
import './bootstrap';

import NiceSelect from 'nice-select2';

import Swiper from 'swiper';
import {Navigation, Pagination, Autoplay} from 'swiper/modules';

const reviews = new Swiper('.testimonials .swiper', {
    modules: [Navigation, Pagination, Autoplay],

    slidesPerView: 1,
    spaceBetween: 20,

    autoplay: {
        delay: 2500,
        disableOnInteraction: true,
    },

    pagination: {
        el: ".swiper-pagination",
    },

    navigation: {
        nextEl: '.testimonials .swiper-button-next',
        prevEl: '.testimonials .swiper-button-prev',
    },

    on: {
        slideChangeTransitionEnd: function () {
            var currentSlide = this.activeIndex;
            var numbers = document.querySelectorAll('.swiper-slide .numbers');
            numbers.forEach(function (number, index) {
                if (index === currentSlide) {
                    number.style.opacity = '1';
                } else {
                    number.style.opacity = '0';
                }
            });
        },
    }

});

const cards = new Swiper('.services-swiper .swiper', {
    modules: [Navigation, Pagination],

    slidesPerView: 3,
    spaceBetween: 20,

    breakpoints: {

        0: {
            slidesPerView: 1,
            spaceBetween: 0,
        },

        768: {
            slidesPerView: 2,
            spaceBetween: 10,
        },

        992: {
            slidesPerView: 3,
            spaceBetween: 20,
        },
    },


    navigation: {
        nextEl: '.services-swiper .swiper-button-next',
        prevEl: '.services-swiper .swiper-button-prev',
    },

});


/* SERVICES */

// document.addEventListener("DOMContentLoaded", function() {
//
//
//     function toggleServicesDropdown() {
//         var dropdown = document.getElementById("servicesDropdown");
//         var isVisible = dropdown.style.display !== "none";
//         var isFullyExpanded = dropdown.style.maxHeight === dropdown.scrollHeight + "px";
//
//         dropdown.style.transition = "max-height 0.5s ease-in-out";
//         if (isVisible && isFullyExpanded) {
//             dropdown.style.maxHeight = 0;
//
//         } else {
//             dropdown.style.display = "block";
//             dropdown.style.maxHeight = dropdown.scrollHeight + "px";
//         }
//     }
//
//
//     var servicesButton = document.getElementById("servicesDropdownButton");
//     if (servicesButton) {
//         servicesButton.addEventListener("click", toggleServicesDropdown);
//     }
//
// });


document.addEventListener("DOMContentLoaded", function () {

    function toggleServicesDropdown() {
        var dropdown = document.getElementById("servicesDropdown");
        var isVisible = dropdown.style.display !== "none";
        var isFullyExpanded = dropdown.style.maxHeight === dropdown.scrollHeight + "px";

        dropdown.style.transition = "max-height 0.5s ease-in-out";
        if (isVisible && isFullyExpanded) {
            dropdown.style.maxHeight = 0;
            removeOverlay();
        } else {
            dropdown.style.display = "block";
            dropdown.style.maxHeight = dropdown.scrollHeight + "px";
            addOverlay();
        }
    }


    function addOverlay() {
        var overlay = document.createElement("div");
        overlay.id = "overlay";
        overlay.style.position = "fixed";
        overlay.style.top = "0";
        overlay.style.left = "0";
        overlay.style.width = "100%";
        overlay.style.height = "100%";
        overlay.style.backgroundColor = "rgba(0, 0, 0, 0.5)";
        overlay.addEventListener("click", toggleServicesDropdown);
        document.body.appendChild(overlay);
    }


    function removeOverlay() {
        var overlay = document.getElementById("overlay");
        if (overlay) {
            overlay.parentNode.removeChild(overlay);
        }
    }

    var servicesButton = document.getElementById("servicesDropdownButton");
    if (servicesButton) {
        servicesButton.addEventListener("click", toggleServicesDropdown);
    }

});


/* nav-link COLOR */
document.addEventListener("DOMContentLoaded", function () {

    function toggleServicesDropdown() {
        var mainNav = document.getElementById("mainNav");
        mainNav.classList.toggle("dropdown-open");
    }


    var servicesButton = document.getElementById("servicesDropdownButton");
    if (servicesButton) {
        servicesButton.addEventListener("click", toggleServicesDropdown);
    }
});

new NiceSelect(document.getElementById('nice-select'));


/* NUMBER COUNTING */


// document.addEventListener("DOMContentLoaded", function() {
//     // Select the section element
//     var sectionElement = document.querySelector('.intro-desc');
//
//     // Call startCounting when the mouse enters the section
//     sectionElement.addEventListener('mouseenter', function() {
//         var numberElement = document.querySelector('.number');
//         startCounting(numberElement);
//     });
// });
//
// function startCounting(counter) {
//     let target = parseInt(counter.getAttribute('data-target'));
//     if (!isNaN(target)) { // Check if target is a valid number
//         let count = 0;
//         let step = Math.ceil(target / 50);
//
//         let counts = setInterval(function () {
//             count += step;
//             if (count >= target) {
//                 clearInterval(counts);
//                 counter.textContent = target + '+';
//             } else {
//                 counter.textContent = count;
//             }
//         }, 20);
//     }
// }


document.addEventListener("DOMContentLoaded", function () {

    var countingStarted = false;


    var numberElement = document.querySelector('.quantity');
    numberElement.textContent = '0';


    document.addEventListener('scroll', function () {

        var sectionElement = document.querySelector('.intro-desc');
        var rect = sectionElement.getBoundingClientRect();


        if (!countingStarted && rect.top < window.innerHeight && rect.bottom >= 0) {
            startCounting(numberElement);
            countingStarted = true;
        }
    });
});

function startCounting(counter) {
    let target = parseInt(counter.getAttribute('data-target'));
    if (!isNaN(target)) {
        let count = 0;
        let step = Math.ceil(target / 50);

        let counts = setInterval(function () {
            count += step;
            if (count >= target) {
                clearInterval(counts);
                counter.textContent = target + '+';
            } else {
                counter.textContent = count;
            }
        }, 20);
    }
}





